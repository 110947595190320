import React from 'react';
import { Layout } from '../components/shared/Layout';

const DisclaimerPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>Contact</h1>

            <div className="pt-16 pb-8">
              <p>
                Meer weten over de nieuwe schillenboer van Den Haag? Zie je
                mogelijkheden voor samenwerking? Neem contact op via het
                contactformulier, of direct met: Arn van der Pluijm
                arn@haagsche-schil.nl +31 6 1809 2978
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DisclaimerPage;
